<template>
  <div>
    <div class="widget mb-40" style="position: relative">
      <div class="widget-title-box mb-30">
        <span class="animate-border"></span>
        <h3 class="widget-title">Search Objects</h3>
      </div>
      <form class="search-form" v-on:submit.prevent="search()">
        <div>
          <input v-model="searchInp" type="text" placeholder="Search your keyword...">
          <button type="submit"><i class="fas fa-search"></i></button>
        </div>
      </form>
      <div class="search-modal"
         v-click-outside="hide"
         :class="{'open': showPopup}">
        <div>Search: '{{searchInp}}'</div>
        <div class="loading" v-if="searchResultStatus" style="height: 100%">
          <img src="../assets/loading-animacion.gif" alt="">
        </div>

        <div class="recent-post-widget" style="padding: 0" v-else>
          <div class="not-found" style="margin-top: 20px" v-if="searchResult.length === 0">Not Found</div>
          <div class="posts">
            <div class="post" v-for="blog in searchResult" :key="blog.id" v-if="blog.status === 'published'">
              <div class="img-holder">
                <div class="img-wrapper">
                  <img v-if="blog.image" :src="blog.image['data']['full_url']" alt>
                </div>
              </div>
              <div class="details">
                <h5>
                  <a href="" @click.prevent="goToSearchResult(blog)">{{blog.title}}</a>
                </h5>
                <span class="date">{{blog.date | moment("MMMM DD, YYYY")}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="widget mb-40">
      <div class="widget-title-box mb-30">
        <span class="animate-border"></span>
        <h3 class="widget-title">Recent Articles</h3>
      </div>
      <ul class="recent-posts">
        <li class="loading" v-if="loading" style="height: 200px">
          <img src="../assets/loading-animacion.gif" alt="">
        </li>
        <li v-if="news_data.length === 0 && !loading">
          <div class="widget-posts-body">
            <h6 class="widget-posts-title">
              <a>Not Fond</a>
            </h6>
          </div>
        </li>
        <li v-for="(news, i) in news_data" :key="news.id" v-if="i < 3 && !loading">
          <div class="widget-posts-image">
            <a href="" @click.prevent="goToRecentArticles(news.slug)">
              <img v-if="news.image && typeof news.image !== 'number'" :src="news.image['data']['full_url']" alt="">
              <img v-else src="../assets/img/placeholder.png" alt="">
            </a>
          </div>
          <div class="widget-posts-body">
            <h6 class="widget-posts-title">
              <a href="#" @click.prevent="goToRecentArticles(news.slug)">{{news.title}}</a>
            </h6>
            <div class="widget-posts-meta">{{moment(news.date)}}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="widget mb-40">
      <div class="widget-title-box mb-30">
        <span class="animate-border"></span>
        <h3 class="widget-title">Facebook Feeds</h3>
      </div>
      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fhealthtravelsecure&tabs=timeline&width=310&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="310" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
    </div>
    <div class="widget mb-40">
      <div class="widget-title-box mb-30">
        <span class="animate-border"></span>
        <h3 class="widget-title">Instagram Feeds</h3>
      </div>
      <div class="elfsight-app-bcdc4dc6-7ea7-4003-b427-79a133ae3ee3"></div>
    </div>
  </div>
</template>

<script>
    import api from "../services/api";
    import moment from 'moment';
    import ClickOutside from 'vue-click-outside'

    export default {
        name: "news-left-bar",
        data() {
            return {
                searchInp: '',
                showPopup: false,
                searchResultStatus: true,
                loading: true,
                opened: false,
                news_data: [],
            }
        },
        directives: {
            ClickOutside
        },
        async mounted() {
            this.loading = true;
            try {
                this.news_data = await api.newsHomePage();
                if(!this.news_data) this.news_data = [];
                this.loading = false;
            } catch {
                this.news_data = [];
                this.loading = false;
            }
        },
        methods: {
            hide () {
                this.showPopup = false
            },
            moment (date) {
                return moment(date).format('Do MMM YYYY');
            },
            async search() {
                if (this.searchInp !== '') {
                    this.showPopup = true;
                    this.searchResultStatus = true;

                    this.searchResult = await api.search(this.searchInp);
                    this.searchResult = this.searchResult.data;

                    this.searchResultStatus = false;
                }
            },
            params(newParams) {
                return {
                    ...this.$route.params,
                    ...newParams
                }
            },
            goToRecentArticles(slug) {
                const path = `/article/${slug}`;
                if (this.$route.path !== path) this.$router.push(path);
            },
            goToSearchResult(blog) {
                window.scrollTo(0, 0);
                this.showPopup = false;
                const path = `/article/${blog.slug}`;
                if (this.$route.path !== path) this.$router.push(path);
                this.searchInp = '';
            }
        }
    }
</script>

<style scoped>
  .toggle-button {
    position: fixed;
    left: -200000px;
    top: -2000000px
  }

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .not-found {
    border: 1px solid #ebebeb;
    padding: 30px;
    text-align: center;
  }

  .search-modal {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    bottom: -300px;
    position: absolute;
    left: -1px;
    right: -1px;
    height: 300px;
    background: #fff;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(200, 183, 255, 0.2);
    padding: 15px 30px 30px;
  }

  .search-modal.open {
    visibility: visible;
    opacity: 1;
  }

  .search-modal .post {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #f2f0f0;
  }

  .search-modal .widget {
    padding: 0;
    border: 0;
  }

  .search-modal .widget a {
    cursor: pointer;
  }

  .search-modal .posts {
    overflow-y: auto;
    height: 250px;
  }

  .search-modal .post {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .search-modal .post .img-holder {
    width: 20%;
  }

  .search-modal .post .img-holder .img-wrapper {
    height: 50px;
    width: 50px;
  }

  .search-modal .post .img-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .search-modal .post .details {
    width: 80%;
    padding-left: 10px;
  }

  .search-modal .posts::-webkit-scrollbar-track
  {
    border-radius: 10px;
    background-color: #fff;
  }

  .search-modal .posts::-webkit-scrollbar
  {
    width: 8px;
    background-color: #F5F5F5;
  }

  .search-modal .posts::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: #ccc;
  }
</style>
